import React, { Component } from 'react';
import * as firebase from 'firebase';
import { Route } from 'react-router-dom';
import Member from './Member';
import Stat from './Stat';
import Milestone from './Milestone';
import PasswordModal from './PasswordModal';

export default class Project extends Component{

  constructor(props){
    super(props);
    this.state = {
      project: null,
      password: "",
      openPasswordModal: false
    };
  }

  componentWillReceiveProps(props){
    var projectsRef = firebase.database().ref(`projects/${props.match.params.id}`);
    projectsRef.on('value', (snapshot) => {
      if(snapshot.val()){
        this.setState({
          project: snapshot.val()
        });
      }
    });
  }

  componentDidMount(){
    var projectID = this.props.match.params.id;
    var projectsRef = firebase.database().ref(`projects/${projectID}`);
    projectsRef.on('value', (snapshot) => {
      console.log(snapshot);
      if(snapshot.val()){
        this.setState({
          project: snapshot.val()
        });
      }
    });
  }

  onPasswordModalToggle = () => {
    this.setState({
      openPasswordModal: !this.state.openPasswordModal
    });
  }

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value
    });
  }

  onClickedJoin = () => {
    this.onPasswordModalToggle();
  }

  onJoin = (e) => {
    e.preventDefault();
    // check password
    if (this.state.project.password===this.state.password){
      this.joiningUser();
      this.onPasswordModalToggle();
    }
  }

  joiningUser = () => {
    var user = {
      email: this.props.user.email,
      name: this.props.user.displayName,
      photoURL: this.props.user.photoURL
    }
    // var projectID = this.props.match.params.id;
    // var projectsRef = firebase.database().ref(`projects/${projectID}`);
    // if (!this.state.project.contains(user)){
    //   var member = this.state.project.concat(user);
    //   console.log();
    // }
    console.log(user);
  }

  render(){

    if(this.state.project){
      return(
        <div className="fullscreen-container fz-project">
          <PasswordModal
            show={this.state.openPasswordModal}
            onToggle={this.onPasswordModalToggle}
            onJoin={this.onJoin}
            password={this.state.password}
            onPasswordChange={this.onPasswordChange}
          />
          <div className="fz-project-header">
            {
              !(this.state.project.ready) &&
                <div className="fz-project-soon">COMING SOON</div>
            }
            <div className="fz-project-header-label">{this.state.project.type}</div>
            <div className="fz-project-header-title">{this.state.project.title}</div>
            <div className="fz-project-header-tagline">{this.state.project.tagline}</div>
            <div className="fz-project-header-action">
              <div className="fz-project-header-action-button">
                <div className="fz-project-header-action-button-name"><i className="material-icons">add</i> MEMORY</div>
                <div className="fz-project-header-action-button-name"><i className="material-icons">add</i> MILESTONE</div>
                {/* <input className="fz-project-header-action-input" type="text" placeholder="Invitation Code" /> */}
                <div className="fz-project-header-action-button-right">
                  <div className="fz-project-header-action-button-name" onClick={this.onClickedJoin}>JOIN</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="fz-project-body">
            <div className="fz-project-soon">COMING SOON</div>
          </div> */}
          <div className="fz-project-body">
            <Stat
              projectid={this.props.match.params.id}
              memories={this.state.project.memories}
              milestones={this.state.project.milestones}
              bakers={this.state.project.member}/>
            <Route exact path="/projects/:id/member" render={() => <Member member={this.state.project.member}/>} />
            <Route exact path="/projects/:id/milestones" render={() => <Milestone milestones={this.state.project.milestones}/>} />

          </div>
        </div>
      );
    }

    return(
      <div className="fullscreen-container fz-project">
        <div className="fz-project-header">
          <div className="fz-project-header-label">LOADING</div>
        </div>
      </div>
    );
  }
}
