import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../assets/fzlogo.svg';

export default class Profile extends Component{

  render(){
    return(
      <div>
        { (this.props.user) &&
          <div className="fz-menu-profile">
            <img src={logo} className="fz-menu__logo" alt="profile_img"/>
            <img src={this.props.user.photoURL} className="fz-menu-profile-picture" alt="profile_img"/>
            <Link className="fz-menu-profile-name" to="/signup">{this.props.user.displayName}</Link>
          </div>
        }
        { !(this.props.user) &&
          <div className="fz-menu-profile">
            <img src={logo} className="fz-menu__logo" alt="profile_img"/>
            <Link to="/signup" className="fz-menu-linksignup">Introduce Yourself</Link>
          </div>
        }
      </div>
    );
  }
}
