import React, {Component} from 'react';
import loadingGif from '../../assets/loading.gif';

export default class Loading extends Component{
  render(){
    return(
      <div className="fz-loading">
        <div className="fz-loading__background">
          <div className="fz-loading__container">
            <img src={loadingGif} className="fz-loading__gif" alt="Loading.."/>
          </div>
        </div>
      </div>
    );
  }
}
