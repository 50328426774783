import React, { Component } from 'react';

export default class PasswordModal extends Component{

  render(){
    if (this.props.show){
      return(
        <div className="fz-modal">
          <div className="fz-modal-inner">
            <div className="fz-modal-close" onClick={this.props.onToggle}><i className="material-icons">close</i></div>
            <div className="fz-modal-message">
              This project is private. You need to input invitation code to join.
            </div>
            <form className="fz-modal-form">
              <div className="fz-modal-input-container">
                <input className="fz-modal-input" type="text" placeholder="Invitation Code" value={this.props.password} onChange={this.props.onPasswordChange}/>
              </div>
              <div className="fz-modal-button-container">
                <button className="fz-modal-button" onClick={this.props.onJoin}>JOIN</button>
              </div>
            </form>
          </div>
        </div>
      );
    }

    return <div></div>;
  }
}
