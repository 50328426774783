import React, {Component} from 'react';
import moment from 'moment';
import firebase from 'firebase';
import ReactMarkdown from 'react-markdown';

import Loading from '../Loading';

export default class Article extends Component{

  constructor(props){
    super(props);
    this.state = {
      title: '',
      content: '',
      date: moment(),
      location_name: ''
    };
  }

  componentDidMount(){
    var postID = this.props.match.params.id;
    var firestore = firebase.firestore();
    firestore.settings({timestampsInSnapshots: true});
    var articleRef = firestore.collection("posts").doc(postID);

    // get post with id = postID
    articleRef.get().then((doc)=>{
      if (doc.exists){
        var post = doc.data();
        this.setState({
          title: post.title,
          content: post.content,
          date: moment(post.datetime),
          location_name: post.location_name,
        });
      }else{
        this.props.history.push('/posts');
      }
    }).catch((error)=>{
      this.props.history.push('/posts');
    });
  }

  render(){
    if(!!this.state.content){
      return(
        <div className="fz-article">
          <div className="fz-article__background" />
          <div className="fz-article__container">
            <div onClick={this.props.history.goBack} className="fz-article__close-button">
              <i className="material-icons">close</i>
            </div>
            <h1 className="fz-article__title">{this.state.title}</h1>
            <ReactMarkdown className="fz-article__content" source={this.state.content} escapeHtml={false}/>
          </div>
        </div>
      );
    }
    return(
      <Loading />
    );
  }
}
