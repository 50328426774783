import React from 'react';
import ReactDOM from 'react-dom';
import * as firebase from 'firebase';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

// Initialize Firebase
var config = {
  apiKey: "AIzaSyCWVkQULdeVfJPkYWzigQeZm8AlSrYYCzY",
  authDomain: "fauzanmrifqy.firebaseapp.com",
  databaseURL: "https://fauzanmrifqy.firebaseio.com",
  projectId: "fauzanmrifqy",
  storageBucket: "fauzanmrifqy.appspot.com",
  messagingSenderId: "323055266764"
};
firebase.initializeApp(config);

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
