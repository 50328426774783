import React, {Component} from 'react';
import firebase from 'firebase';

export default class Editor extends Component{

  constructor(props){
    super(props);
    this.state = {
      title: '',
      content: ''
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    var fstore = firebase.firestore();
    fstore.settings({timestampsInSnapshots: true});
    var postRef = fstore.collection("posts");
    postRef.add({
      title: this.state.title,
      content: this.state.content
    }).then((docRef) => {
      this.setState({
        title: '',
        content: ''
      });
    }).catch((error) => {
      console.error("Error submit post :", error);
    });
  }

  onTitleChange = (e) => {
    this.setState({
      title: e.target.value
    });
  }

  onContentChange = (e) => {
    this.setState({
      content: e.target.value
    });
  }

  render(){
    return(
      <div className="fz-editor">
        <form className="fz-editor__form" onSubmit={this.onSubmit}>
          <input className="fz-input" type="text" placeholder="Title" value={this.state.title} onChange={this.onTitleChange}/>
          <textarea className="fz-input" type="text" placeholder="Content" value={this.state.content} onChange={this.onContentChange}/>
          <button className="fz-button" type="submit">Submit</button>
        </form>
      </div>
    );
  }
}
