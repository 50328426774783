import React, { Component } from 'react';
import * as firebase from 'firebase';
import { NavLink } from 'react-router-dom';
import { slide as RMenu } from 'react-burger-menu';
import Profile from './Profile';

export default class Menu extends Component {

  constructor(props){
    super(props);

    this.state = {
      projects: [],
      isOpen: false
    }
    this.projectsRef = firebase.database().ref('projects');
  }

  closeMenu = () => {
    this.setState({
      isOpen: false
    });
  }

  componentDidMount(){
    this.projectsRef.on('value', (snapshot) => {
      if(snapshot.val()){
        this.setState({
          projects: snapshot.val()
        });
      }
    });
  }

  render(){
    return(
      <RMenu isOpen={this.state.isOpen}>
        <div>
          <Profile
            user={this.props.user}
            onLogout={this.props.onLogout}
          />
          <div className="fz-menu-navigation">
            <div className="fz-menu-navigation-label">MENU</div>
            <NavLink to="/" className="fz-menu-link menu-item">Homepage</NavLink>
            <NavLink to="/posts" className="fz-menu-link menu-item">Post</NavLink>
            <NavLink to="/contact" className="fz-menu-link menu-item">Contact</NavLink>
            {/* {this.state.projects.map((project, index)=>{
              return(
                <NavLink key={`menu${index}`} to={`/projects/${index}`} className="fz-menu-link menu-item" onClick={this.closeMenu}>{project.short_title}</NavLink>
              );
            })} */}
          </div>
          <div className="fz-menu-dark-mode" onClick={this.props.toggleDarkMode}>
            <i className="material-icons">brightness_6</i> <span className="fz-menu-dark-mode__label">{ this.props.darkMode ? 'Light Mode' : 'Dark Mode' }</span>
          </div>
        </div>
      </RMenu>
    );
  }
}
