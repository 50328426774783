import React ,{ Component } from 'react';

export default class Contact extends Component{
  render(){
    return(
      <div className="my-contact">
        <div className="my-contact-soon">
          <div className="my-contact-title">Content Coming Soon</div>
          <div className="my-contact-message">Looking for Frontend • UI Developer? Let me know your needs.</div>
        </div>
        <div>

        </div>
        <div className="my-contact-footer">
          <div className="my-contact-footer-social">
            <a href="mailto:fauzanriff@gmail.com?subject=Greetings%20from%20your%20website" target="_blank" className="my-contact-footer-media email">
              Email
            </a>
            <a href="https://www.linkedin.com/in/fauzanrifqy" target="_blank" className="my-contact-footer-media linkedin">
              LinkedIn
            </a>
            <a href="https://github.com/fauzanriff" target="_blank" className="my-contact-footer-media github">
              Github
            </a>
            <a href="https://www.instagram.com/fauzanriff" target="_blank" className="my-contact-footer-media instagram">
              Instagram
            </a>
          </div>
          <div className="my-contact-footer-copyr">
            © 2019 <strong>fauzan</strong>rifqy Indonesia.
          </div>
        </div>
      </div>
    );
  }
}
