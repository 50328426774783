import React, { Component } from 'react';
import * as firebase from 'firebase';
import { Motion, spring } from 'react-motion';

export default class SignUp extends Component{

  constructor(props){
    super(props);

    this.state={
      token: {}
    }

    this.provider = new firebase.auth.FacebookAuthProvider();
    this.provider.addScope('public_profile');
    this.provider.addScope('email');
  }

  componentWillMount(){
    firebase.auth().getRedirectResult().then((result) => {
      if (result.credential) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // var token = result.credential.accessToken;
      }
      // The signed-in user info.
      // var user = result.user;
    }).catch((error) => {
      // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // The email of the user's account used.
      // var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;
      // ...
    });
  }

  onLoginWithFacebook = () => {
    firebase.auth().signInWithRedirect(this.provider);
  }

  render(){

    var stifdam = {
      stiffness: 120,
      damping: 10
    }

    var motionDefaultStyle = {
      y: -500,
    }

    var motionStyle = {
      y: spring(0, stifdam),
    }

    return(
      <div>
        <Motion
          key={`auth${this.props.user}`}
          defaultStyle={motionDefaultStyle}
          style={motionStyle}>
          {(style)=>(
            <div className="fullscreen-container fz-auth-card-container">
              { !(this.props.user) &&
                <div
                  style={{
                    transform: `translateY(${style.y}px)`
                  }}
                  className="fz-auth-card">
                  <div className="fz-auth-card-title">INTRODUCE YOURSELF</div>
                  <div className="fz-auth-card-text">It will be my honor to know you better.</div>
                  <div className="fz-auth-card-inner">
                    <div className="fz-auth-card-button facebook" onClick={this.onLoginWithFacebook}>Introduce using <strong>Facebook</strong></div>
                  </div>
                </div>
              }
              { (this.props.user) &&
                <div
                  style={{
                    transform: `translateY(${style.y}px)`
                  }}
                  className="fz-auth-card">
                  <div className="fz-auth-card-text margin">Hi, Nice to meet you</div>
                  <img src={this.props.user.photoURL} className="fz-auth-card-photo" />
                  <div className="fz-auth-card-title margin">{this.props.user.displayName}</div>
                  <div className="fz-auth-card-inner">
                    <div className="fz-auth-card-button facebook" onClick={this.props.onLogout}>Logout <strong>Facebook</strong></div>
                  </div>
                </div>
              }
            </div>
          )}
        </Motion>
      </div>
    );
  }
}
