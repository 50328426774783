import React, { Component } from 'react';
import { Motion, spring } from 'react-motion';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PropTypes from 'prop-types';

const tagline = ["create", "design", "build"];

export default class Greetings extends Component{

  constructor(){
    super();

    this.state = {
      shownText: 0,
      animate: 0
    };
  }

  static propTypes = {
    className: PropTypes.string,
    listOfTexts: PropTypes.array,
    greetingsTickTime: PropTypes.number
  };

  greetingsTick = () => {
    this.setState({
      shownText: ((this.state.shownText+1)%this.props.listOfTexts.length),
      animate: this.state.animate+1
    });
  }

  componentDidMount(){
    this.intervalGhost = setInterval(this.greetingsTick, this.props.greetingsTickTime);
  }

  componentWillUnmount(){
    clearInterval(this.intervalGhost);
  }

  render(){

    var stifdam = {
      stiffness: 30,
      damping: 26
    }

    var greetingsDefaultStyle = {
      letter_spacing: 0,
      opacity: 0,
    }

    var greetingsStyle = {
      letter_spacing: spring(10, stifdam),
      opacity: spring(1, stifdam)
    }

    return(
      <div className={this.props.className}>
        <Motion
          key={this.state.animate}
          defaultStyle={greetingsDefaultStyle}
          style={greetingsStyle}
        >
          {(style) => (
            <div className="greetings-container">
              <span
                className="greetings-title"
                style={{
                  letterSpacing: style.letter_spacing,
                  opacity: style.opacity
                }}
                >{this.props.listOfTexts[this.state.shownText]}</span>
              <span className="greetings-tagline">I create web & mobile apps.</span>
            </div>
          )}
        </Motion>
      </div>
    );
  }
}
