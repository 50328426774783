import React ,{ Component } from 'react';
import Particles from 'react-particles-js';
import Contact from './Contact';

export default class IntroduceSelf extends Component{

  constructor(props) {
    super(props);

    this.state = {
      dotColor: this.props.darkMode ? '#ffffff' : '#1b2b34',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.darkMode !== this.props.darkMode) {
      this.setState({
        dotColor: nextProps.darkMode ? '#ffffff' : '#1b2b34',
      });
    }
  }

  render(){

    var particlesConfig = {
      particles:{
        number:{
          value: 30,
          density:{
            enable: false
          },
        },
        color:{
          value: this.state.dotColor,
        },
        line_linked:{
          enable: true,
          distance: 150,
          color: this.state.dotColor,
        },
        size:{
          value: 4,
          random: true,
        },
        move:{
          speed: 1,
          direction: 'none',
          random: true,
          out_mode: 'out',
          attract:{
            enable: true,
          },
        },
      },
      interactivity:{
        detect_on: 'canvas',
        events:{
          onhover:{
            enable: true,
            mode: 'grab',
          },
          onclick:{
            enable: true,
            mode: 'push',
          },
        },
        modes:{
          grab:{
            distance: 150,
            line_linked:{
              opacity: 0.8,
            }
          },
        },
      },
    }

    return(
      <div className="my-introduce">
        {/* <div className="my-introduce-hi">Hi everyone,</div>
        <div className="my-introduce-hi-myname">My name is</div>
        <div className="my-introduce-hi-fauzan"><span className="bold">Fauzan</span> Rifqy</div>
        <div className="my-introduce-hi-text hi-1">I <span className="red-dark">wonder</span> what <span className="red-dark">bring you</span> here, but welcome to my little pieces of..</div>
        <div className="my-introduce-hi-text hi-2">I don't know, should i called it my PORTOFOLIO?</div>
        <div className="my-introduce-hi-text hi-3">I didn't <span className="blue-dark">think</span> that <span className="red-dark">far</span>. <span className="blue-dark">Why</span> i'd come up to build this <span className="red-dark">a</span>ny<span className="red-dark">way.</span></div>
        <div className="my-introduce-hi-text hi-4">Yeah, maybe <span className="blue-dark">just</span> to show off <span className="littlebit"><span className="blue-dark">a</span> little <span className="blue-dark">bit.</span></span></div> */}
        <Contact />
        <Particles
          className="welcome-page-particles"
          height={window.innerHeight}
          width={window.innerWidth}
          params={particlesConfig}/>
      </div>
    );
  }
}
