import React, { Component } from 'react';

export default class Milestone extends Component{
  render(){

    if(this.props.milestones){
      return(
        <div className="fz-milestones">
          {
            this.props.milestones.map((milestone, index)=>{
              return(
                <div key={`milestone${index}`} className="fz-milestones-item">
                  <div className={`fz-milestones-item-name ${milestone.done ? "done":""}`}>{milestone.name}</div>
                  { (milestone.comments) &&
                    <div className="fz-milestones-item-comment">{milestone.comments.length} comments</div>
                  }
                </div>
              );
            })
          }
        </div>
      );
    }

    return(
      <div className="fz-milestones">
        not milestone yet.
      </div>
    );
  }
}
