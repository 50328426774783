import React, { Component } from 'react';
import { Fullpage, Slide } from 'fullpage-react';
import WelcomePage from './WelcomePage';

export default class Homepage extends Component {

  fullPageOptions = {
    // for mouse/wheel events
    // represents the level of force required to generate a slide change on non-mobile, 10 is default
    scrollSensitivity: 5,

    // for touchStart/touchEnd/mobile scrolling
    // represents the level of force required to generate a slide change on mobile, 10 is default
    touchSensitivity: 5,
    scrollSpeed: 300,
    hideScrollBars: true,
    enableArrowKeys: true
  };

  render(){

    var verticalSlides = [
      <Slide> <WelcomePage darkMode={this.props.darkMode} /> </Slide>
    ];

    this.fullPageOptions.slides = verticalSlides;
    return(
      <div id="app" className="fullscreen-container">
        <div className="fullscreen-container-fullpage">
          <Fullpage {...this.fullPageOptions}/>
        </div>
      </div>
    );
  }
}
