import React, { Component } from 'react';
import Particles from 'react-particles-js';
import { Motion, spring } from 'react-motion';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Greetings from './Greetings';

// 3D5AFE

export default class WelcomePage extends Component {

  constructor(props){
    super(props);

    this.state = {
      listOfGreetings: [
        "Hello World",
        "Halo Dunia",
        "Hola Mundo",
        "你好，世界",
        "Pozdrav Svijete",
        "Hej Verden",
        "Hallo Wereld",
        "Hei Maailma",
        "Bonjour Le Monde",
        "Hallo Welt",
        "Γειά σου Κόσμε",
        "હેલો વર્લ્ડ",
        "Ciao Mondo",
        "こんにちは世界",
        "안녕하세요 세계",
        "Witaj świecie",
        "Hej Världen",
        "Selam Dünya",
      ],
      dotColor: this.props.darkMode ? '#ffffff' : '#1b2b34',
    };
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.darkMode !== this.props.darkMode) {
      this.setState({
        dotColor: nextProps.darkMode ? '#ffffff' : '#1b2b34',
      });
    }
  }

  defineParticlesValue = () => {
    const square = 300000;
    const particlesPerSurface = 25;
    var width = document.getElementById('app').offsetWidth;
    var height = document.getElementById('app').offsetHeight;
    return (Math.trunc((width*height)/square))*particlesPerSurface;
  }

  render(){

    var particlesConfig = {
      particles:{
        number:{
          value: this.defineParticlesValue() | 25,
          density:{
            enable: false
          },
        },
        color:{
          value: this.state.dotColor,
        },
        line_linked:{
          enable: true,
          distance: 150,
          color: this.state.dotColor,
        },
        size:{
          value: 4,
          random: true,
        },
        move:{
          speed: 1.5,
          direction: 'none',
          random: true,
          out_mode: 'out',
          attract:{
            enable: true,
          },
        },
      },
      interactivity:{
        detect_on: 'canvas',
        events:{
          onhover:{
            enable: true,
            mode: 'grab',
          }
        },
        modes:{
          grab:{
            distance: 150,
            line_linked:{
              opacity: 0.8,
            }
          },
        },
      },
    };

    var stifdam = {
      stiffness: 20,
      damping: 26
    }

    var motionDefaultStyle = {
      opacity: 0,
    }

    var motionStyle = {
      opacity: spring(1, stifdam)
    }

    return(
      <div className="welcome-page">
        <div className="welcome-page-content">
          <Motion
            defaultStyle={motionDefaultStyle}
            style={motionStyle}>
            { (style) => (
              <div
                style={{opacity: style.opacity}}
                className="welcome-page-content-inner">
                <Greetings
                  listOfTexts={this.state.listOfGreetings}
                  className="welcome-page-hello-container"
                  greetingsTickTime={4000}
                />
                <div className="welcome-page-title-container">
                  <span className="welcome-page-title"><strong>fauzan</strong>rifqy</span>
                  <span className="welcome-page-subtitle">Frontend Developer • UI Engineer</span>
                </div>
              </div>
            )}
          </Motion>
          <Particles
            key="particles"
            className="welcome-page-particles"
            height={window.innerHeight}
            width={window.innerWidth}
            params={particlesConfig}/>
        </div>
      </div>
    );
  }
}
