import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class Stat extends Component{
  render(){
    return(
      <div className="fz-project-stat">
        <NavLink to={`/projects/${this.props.projectid}/milestones`} className="fz-project-stat-item" activeClassName="active">
          <div className="fz-project-stat-item-value">{this.props.milestones ? this.props.milestones.length:"0"}</div>
          <div className="fz-project-stat-item-label">Milestones</div>
        </NavLink>
        <NavLink to={`/projects/${this.props.projectid}/member`} className="fz-project-stat-item" activeClassName="active">
          <div className="fz-project-stat-item-value">{this.props.bakers ? this.props.bakers.length:"0"}</div>
          <div className="fz-project-stat-item-label">Bakers</div>
        </NavLink>
      </div>
    );
  }
}
