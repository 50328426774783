import React, {Component} from 'react';
import * as firebase from 'firebase';
import moment from 'moment';
import "firebase/firestore";
import ReactMarkdown from 'react-markdown';
import Card from './Card';
import Loading from '../Loading';

export default class Post extends Component{

  constructor(props){
    super(props);
    this.state = {
      posts: null,
    };
  }

  componentDidMount(){
    var postID = this.props.match.params.id;
    var fstore = firebase.firestore();
    fstore.settings({timestampsInSnapshots: true});
    var postRef = fstore.collection("posts");
    postRef.orderBy("datetime", "desc").onSnapshot(posts => {
      var holder = [];
      posts.forEach(post => {
        var postObject = post.data();
        postObject.id = post.id;
        holder.push(postObject);
      });
      this.setState({
        posts: holder,
      });
    });
  }

  goToReadMore = (id) => {
    this.props.history.push(`/posts/${id}`)
  }

  render(){
    if (this.state.posts){
      return(
        <div className="fullscreen-container fz-post">

          <div className="fz-post__container">
            <div className="fz-post__slider">

              { this.state.posts.map((post, index) => {
                const dateContainer = moment(post.datetime.toDate());
                return (
                  <Card
                    key={index}
                    title={post.title}
                    content={post.content}
                    date={dateContainer}
                    onReadMore={() => this.goToReadMore(post.id)}/>
                );
              })}

            </div>
          </div>
        </div>
      );
    }
    return (
      <Loading />
    );
  }
}
