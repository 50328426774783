import React, { Component } from 'react';
import * as firebase from 'firebase';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Homepage, Project, Menu, SignUp, Post, Editor, Article, Contactpage } from './components';

class App extends Component {

  constructor(props){
    super(props);

    this.state = {
      user: null,
      darkMode: false
    }
  }

  componentDidMount(){
    this.onAuth();
  }

  onAuth = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          user: user
        });
      }
    });
  }

  onLogout = () => {
    firebase.auth().signOut().then(() =>{
      this.setState({
        user: null
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  toggleDarkMode = () => {
    if (!this.state.darkMode) {
      document.body.style.backgroundColor = "#16232a";
      document.body.style.color = "#edf3f8";
    } else {
      document.body.style.backgroundColor = "#edf3f8";
      document.body.style.color = "#16232a";
    }
    this.setState({
      darkMode: !this.state.darkMode
    });
  }

  render() {
    return (
      <div className={"App " + (this.state.darkMode ? 'dark-mode' : '')}>
        <BrowserRouter>
          <div>
            <Menu
              user={this.state.user}
              toggleDarkMode={this.toggleDarkMode}
              darkMode={this.state.darkMode}
            />
            <Switch>
              <Route exact path="/" render={(props) => <Homepage {...props} darkMode={this.state.darkMode} />} />
              <Route exact path="/contact" render={(props) => <Contactpage {...props} darkMode={this.state.darkMode} />} />
              <Route path="/projects/:id" render={(props) => <Project {...props} user={this.state.user} />} />
              <Route exact path="/signup" render={(props) => <SignUp {...props} user={this.state.user} onLogout={this.onLogout}/>} />
              <Route exact path="/posts" render={(props) => <Post {...props} user={this.state.user} />} />
              <Route path="/posts/:id" render={(props) => <Article {...props} user={this.state.user} />} />
              <Route path="/admin" render={(props) => <Editor {...props} user={this.state.user} />} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
