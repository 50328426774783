import React, { Component } from 'react';
// import * as firebase from 'firebase';

export default class Member extends Component{
  render(){

    if (this.props.member){
      return(
        <div className="fz-project-member">
          { this.props.member.map((user, index)=>{
              return(
                <div key={`member${index}`} className="fz-project-user">
                  <div className="fz-project-user-picture">
                    <img src={user.photoURL} className="fz-picture" />
                  </div>
                  <div className="fz-project-user-name">
                    {user.name}
                  </div>
                </div>
              );
            })
          }
        </div>
      );
    }

    return(
      <div className="fz-project-member">
        not member yet
      </div>
    );
  }
}
