import React, {Component} from 'react';
import ReactMarkdown from 'react-markdown';

export default class Card extends Component{

  constructor(props){
    super(props);
  }

  render(){
    if(this.props.title && this.props.content){
      return(
        <div className="fz-post__card">
          <div className="fz-post__card__label">
            <div className="fz-post__card__date-container">
              <p className="fz-post__card__month">
                {this.props.date.format('MMM')}
              </p>
              <p className="fz-post__card__date">
                {this.props.date.format('D')}
              </p>
              <p className="fz-post__card__year">
                {this.props.date.format('YYYY')}
              </p>
            </div>
          </div>
          <div className="fz-post__card__content">
            <div className="fz-post__card__title-container">
              <h1 className="fz-post__title">{this.props.title}</h1>
              <p className="fz-post__date-below-title">{this.props.date.format('D MMM YYYY')}</p>
            </div>
            <ReactMarkdown className="fz-post__content" source={this.props.content} escapeHtml={false}/>
            <div className="fz-post__actions">
              <button className="fz-post__button fz-post__button_action"><i className="material-icons">favorite</i></button>
              <button className="fz-post__button fz-post__button_action"><i className="material-icons">comment</i></button>
              <button className="fz-post__button fz-post__button_more" onClick={this.props.onReadMore}>Read more</button>
            </div>
          </div>
        </div>
      );
    }
    return(
      <div></div>
    );
  }
}
